import React, { useState, useEffect } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import {
  FormFieldPassword,
  FormFieldCheckbox,
  FormFieldSelect,
  LogoText,
  ButtonPrimary,
  AlertError,
  Stack,
  FlexRow,
  Heading1,
  Formik,
  Form,
  FormFieldText,
  ResponsiveFormContainer,
  FormFieldGroup,
  ParaSmall,
  InfoIcon,
  ControlledToolTip
} from '@papertrail/styleguide'
import { Link, useHistory } from 'react-router-dom'
import { useIndustriesApi, useCountriesApi, useSignupApi } from './hooks'
import { SignupData } from './types'
import { useTitle, Tracking } from '@papertrail/web3-utils'
import * as yup from 'yup'
import { PASSWORD_REGEX } from '../../consts'
import { PapertrailSession } from '@papertrail/web3-session'
import { Partner } from '@papertrail/types'

enum RequestState {
  Initial,
  Loading,
  Loaded,
  Error
}

export type Props = {
  prevRoute?: string
}

export default function Signup(props: Props) {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [loadingRequestState, setLoadingRequestState] = useState(RequestState.Initial)
  const [signupMessage, setSignupMessage] = useState('')
  const [passwordTooltipOpen, setPasswordTooltipOpen] = useState(false)
  const history = useHistory()

  const [countries, loadCountries] = useCountriesApi()
  const [industries, loadIndustries] = useIndustriesApi()
  const [signupState, doSignup] = useSignupApi()

  useTitle('Sign up')

  useEffect(() => {
    loadIndustries({})
    loadCountries({})
  }, [])

  useEffect(() => {
    if (countries.isLoaded && industries.isLoaded) {
      setLoadingRequestState(RequestState.Loaded)
    } else if (countries.isError || industries.isError) {
      setLoadingRequestState(RequestState.Error)
    }
  }, [countries, industries])

  useEffect(() => {
    if (signupState.isError) {
      if (emailIsRegistered(signupState.error)) {
        setSignupMessage('Email already registered, please sign in')
      } else {
        setSignupMessage('Sorry we were unable to sign you up')
      }
    } else if (signupState.isLoaded) {
      if (props.prevRoute && props.prevRoute.startsWith('/imports')) {
        Tracking.trackEvent('signup', { with_imports: true })
        history.replace(props.prevRoute)
      } else {
        Tracking.trackEvent('signup')
        history.replace(`/accounts?product_tour_id=333123`)
      }
    }
  }, [signupState])

  function emailIsRegistered(error) {
    return (
      error.details &&
      error.details.data &&
      error.details.data.email &&
      signupState.error.details.data.email[0].code == 'unique'
    )
  }

  async function signUp(values) {
    let result

    try {
      result = await executeRecaptcha('signup')
    } catch {
      // we are in test mode
    }

    const data = {
      ...values,
      reCaptchaCode: result
    } as SignupData

    setSignupMessage('')
    doSignup(data)
  }

  const validationSchema = yup.object({
    email: yup
      .string()
      .required('Email is required')
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Please enter a valid email'),
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    countryCode: yup.string().notOneOf(['null'], 'Country is required'),
    industry: yup.string().notOneOf(['null'], 'Industry is required'),
    phoneNumber: yup
      .string()
      .required('Phone number is required')
      .min(8, 'Please enter your full number including country code')
      .matches(/[0-9()-+,]+$/, 'Please enter a valid phone number'),
    companyName: yup.string().required('Company name is required'),
    termsChecked: yup.boolean().oneOf([true], 'Please accept the terms of service'),
    password: yup.string().required('Password is required').matches(PASSWORD_REGEX, 'Password must meet requirements'),
    confirmPassword: yup
      .string()
      .required('Please confirm your password')
      .oneOf([yup.ref('password')], 'Passwords do not match')
  })

  const submitEnabled = !signupState.isLoading
  const submitText = !signupState.isLoading ? 'Sign up' : 'Please wait...'
  const partner: Partner = PapertrailSession.partner

  return (
    <Stack p={6} spacing={6} maxWidth={'800px'} width={'stretch'} alignContent="center">
      <FlexRow justifyContent={'space-between'}>
        {partner.images.publicLogo && (
          <img id="papertrail-logo" width="120px" src={partner.images.publicLogo} alt={partner.partnerName + ' logo'} />
        )}

        {!partner.images.publicLogo && <LogoText />}

        <div>
          Go to <a href="https://www.papertrail.io">website</a>
        </div>
      </FlexRow>

      <div>
        <Heading1>Create a free account today</Heading1>
        <div>No credit card or commitment required. Cancel anytime.</div>
      </div>

      {(loadingRequestState === RequestState.Initial || loadingRequestState === RequestState.Loading) && (
        <div>Loading...</div>
      )}

      {loadingRequestState === RequestState.Error && (
        <AlertError>An error encountered while attempting to load data.</AlertError>
      )}

      {loadingRequestState === RequestState.Loaded && (
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            companyName: '',
            phoneNumber: '',
            password: '',
            industry: 'null', // mui doesn't accept an empty string as a key
            countryCode: 'null',
            confirmPassword: '',
            termsChecked: false
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            signUp(values)
          }}>
          <Form>
            <ResponsiveFormContainer columns={2}>
              <div>
                <FormFieldGroup>
                  <FormFieldText label="First name" placeholder="Enter first name" name="firstName" />
                  <FormFieldText label="Last name" placeholder="Enter last name" name="lastName" />
                </FormFieldGroup>
                <FormFieldText label="Email address" placeholder="Your work email" name="email" />
                <FormFieldText label="Phone number" placeholder="+44 1234 567890" name="phoneNumber" />

                <FormFieldSelect
                  label="Country"
                  placeholder="Please select..."
                  options={countries.data}
                  name="countryCode"
                />
              </div>
              <div>
                <FormFieldText label="Company name" placeholder="Company name" name="companyName" />
                <FormFieldSelect
                  label="Industry"
                  placeholder="Please select..."
                  options={industries.data}
                  name="industry"
                />
                <FormFieldPassword
                  label={
                    <FlexRow justifyContent={'flex-start'}>
                      Create a Password
                      <ControlledToolTip
                        open={passwordTooltipOpen}
                        title="Your password must be at least 8 characters long and must include at least one of each of the following: numbers, symbols, uppercase and lowercase letters.">
                        <InfoIcon fontSize="small" />
                      </ControlledToolTip>
                    </FlexRow>
                  }
                  onFocus={() => setPasswordTooltipOpen(true)}
                  onBlur={() => setPasswordTooltipOpen(false)}
                  placeholder="Password (min 8 characters)"
                  name="password"
                />
                <FormFieldPassword
                  label="Confirm Password"
                  placeholder="Reenter your password here"
                  name="confirmPassword"
                />
                <FormFieldCheckbox
                  name="termsChecked"
                  data-testid="termsChecked"
                  label={
                    <span>
                      I accept the{' '}
                      <a href="http://www.papertrail.io/legal" target="blank">
                        Terms of Service
                      </a>{' '}
                      and{' '}
                      <a href="http://www.papertrail.io/privacy" target="blank">
                        Privacy Policy
                      </a>
                    </span>
                  }
                />

                {!signupState.isWaitingForAccount && (
                  <Stack paddingTop={2} spacing={2} alignItems={'center'}>
                    <ButtonPrimary fixedWidth data-testid="submit" isSubmit disabled={!submitEnabled}>
                      {submitText}
                    </ButtonPrimary>
                    {signupMessage && (
                      <AlertError>
                        <span data-testid="error">{signupMessage}</span>
                      </AlertError>
                    )}
                    <p>
                      Already have an account? <Link to={'/login'}>Login</Link>
                    </p>
                    <ParaSmall>
                      This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.
                    </ParaSmall>
                  </Stack>
                )}
                {signupState.isWaitingForAccount && (
                  <div data-testid="waiting">Please wait while we are preparing your account...</div>
                )}
              </div>
            </ResponsiveFormContainer>
          </Form>
        </Formik>
      )}
    </Stack>
  )
}
